// Customizable Area Start
import React from "react";
import {
  Platform,
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Link } from 'react-router-dom';
import { Typography } from "@mui/material";
// Customizable Area End

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openbackry: any;
  // Customizable Area End
}

// Customizable Area Start
interface S {
  profile_pic: string;
  tab: string;
  type: string;
}

interface SS { }
import ChefHeader from "../../blocks/dashboard/src/ChefHeader.web";

class Header extends BlockComponent<Props, S, SS> {

  static instance: Header;

  constructor(props: Props) {
    super(props);
    Header.instance = this;

    this.state = {
      profile_pic: localStorage.getItem("profile_pic") || "https://via.placeholder.com/150",
      tab: localStorage.getItem("path") || "DASHBOARD",
      type: localStorage.getItem("user_type") || "",
    };
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    
    const localStorageProfilePic = user?.data.attributes?.profile_pic ?? "https://via.placeholder.com/60";
    this.setState({ profile_pic: localStorageProfilePic })
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    const newType = localStorage.getItem("user_type");
    const newPath = localStorage.getItem("path") ?? "DASHBOARD";
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const localStorageProfilePic = user?.data?.attributes?.profile_pic ?? "https://via.placeholder.com/60";
    if (prevState.profile_pic !== localStorageProfilePic) {
      this.setState({ profile_pic: localStorageProfilePic });
    }
    if (prevState.type !== newType) {
      this.setState({ type: newType || "" });
    }
    if (prevState.tab !== newPath) {
      this.setState({ tab: newPath });
    }
  }


  render() {
    const user = localStorage.getItem('user');
    let useData;

    if (user) {
      try {
        useData = JSON.parse(user);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        useData = null;
      }
    } else {
      useData = null;
    }
    const userObj = useData?.data?.attributes;

    return (
      <div style={{
        width: "100%", display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#ffffff", height: "100%"
      }}>
        <Typography variant='subtitle1' style={{
          color: "#C2B30A", fontWeight: "700", textTransform: "uppercase",
          fontSize: "16px",
          textAlign: "left"
        }}>{this.state.tab}</Typography>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "50px" }}>
          <Link to="/notifications">
            <img src={require("./notifications.svg")} />
          </Link>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
            <img src={this.state.profile_pic} alt='profile-pic' style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly", alignItems: "left" }}>
              <Typography variant='subtitle2' style={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '17.78px',
                textAlign: 'left'
              }}>{userObj?.first_name || ''} {userObj?.last_name || ''}</Typography>
              <Typography variant='body2'>{userObj?.email || ''}</Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
// Customizable Area End