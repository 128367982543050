import React from "react";
import {
    createTheme,
} from "@mui/material/styles";

import PaymentsController, { Props } from "./PaymentsController.web";
import {
    Box, Typography, Button, Card, IconButton, Radio, RadioGroup, FormControlLabel, Dialog, DialogContent,
    DialogTitle,
    Grid,
    CardMedia,
    CardContent,
    DialogActions,
    Snackbar, Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/system';
import { stripeBackground } from "./assets"
import { star, time } from "../../dashboard/src/assets";
import CloseIcon from '@mui/icons-material/Close'

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class UserPayments extends PaymentsController {
    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
            <div style={{ backgroundColor: 'transparent', color: '#fff', padding: '2rem', borderRadius: '8px', marginRight: "10px", width: "100%" }}>
                <Snackbar
                    open={this.state.showToast}
                    autoHideDuration={6000}
                    onClose={this.handleCloseToast}
                    data-test-id="btnClose"
                >
                    <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
                        {this.state.toastMessage}
                    </Alert>

                </Snackbar>

                {this.state.cartItems.length <= 0 ?
                    <div>
                        No cart Items available
                    </div> :

                    <div>

                        <Typography variant="h4" sx={{ color: 'rgba(194, 179, 10, 1)', mb: 2, fontWeight: "700" }}>Checkout</Typography>

                        <div style={{ display: "flex", gap: "40px", justifyContent: "space-between" }}>
                            <Box sx={{ mb: 2, width: "50%" }}>
                                <div style={{ textAlign: "right", marginBottom: "10px" }}>
                                    <Typography variant="h6" sx={{ color: '#fdd835', }}>Order&nbsp;
                                        <span style={{ color: "white" }}>
                                            #{this.state.orderId}
                                        </span>
                                    </Typography>
                                </div>
                                <Typography>Client: {this.state.clientDetails.firstName}&nbsp; {this.state.clientDetails.lastName}</Typography>
                                <Typography>Date: {this.state.clientDetails.date}</Typography>
                                <Typography>Phone: {this.state.clientDetails.phoneNumber}</Typography>
                                <Typography>Hour: 13:23:22</Typography>
                            </Box>

                            <Box sx={{ mb: 2, width: "40%" }}>
                                <Typography variant="h6" sx={{ color: "white", textAlign: "right", marginBottom: "10px" }}>Cost Summary</Typography>
                                <Box>
                                    <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            Products
                                        </div>
                                        <div>
                                            ${this.state.costSummary.products.toFixed(2)}
                                        </div>
                                    </Typography>
                                    <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            Tips
                                        </div>
                                        <div>${this.state.costSummary.tips.toFixed(2)}</div>
                                    </Typography>
                                    <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            Fee
                                        </div>
                                        <div>${(this.state.costSummary.products * 0.02).toFixed(2)}</div>
                                    </Typography>
                                    <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            Tax
                                        </div>
                                        <div>${(this.calculateTax()).toFixed(2)}</div>
                                    </Typography>
                                </Box>
                            </Box>
                        </div>

                        <div style={{ display: "flex", gap: "40px", justifyContent: "space-between" }}>
                            <Card sx={{ backgroundColor: "transparent", color: "white", mb: 2, width: "50%" }}>
                                <div style={styles.cartItems}>
                                    {this.state.cartItems.map((item) => (
                                        <div key={item.id} style={styles.cartItem}>
                                            <img
                                                src={item?.imageUrl?.[0]?.url || 'https://via.placeholder.com/60'}
                                                alt={item.name}
                                                style={styles.itemImage}
                                            />
                                            <div style={styles.itemDetails}>
                                                <Typography variant="h5" style={{ margin: 0, fontWeight: 700 }}>{item.name}</Typography>
                                                <Typography variant="body1" style={{ fontSize: "12px" }}>{item.description}</Typography>
                                                <div style={styles.itemPrice}>
                                                    <span style={styles.discountedPrice}>
                                                        ${item.price?.toFixed(2) || "0.00"}                                                    </span>
                                                </div>
                                                <div data-test-id="quantity" style={{ display: "flex", justifyContent: "flex-end" }}>

                                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: "120px", px: 2, mb: 2, borderRadius: "10px", backgroundColor: "#57534E" }}>
                                                        <IconButton data-test-id="decrement" onClick={() => this.updateQuantityLocally(item.id, item.quantity - 1)} style={{ color: "white" }}><RemoveIcon /></IconButton>
                                                        <Typography>{item.quantity}</Typography>
                                                        <IconButton data-test-id="increment" onClick={() => this.updateQuantityLocally(item.id, item.quantity + 1)} style={{ color: "white" }}><AddIcon /></IconButton>
                                                    </Box>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                            <div style={{ display: "flex", width: "40%", height: "5%", marginTop: "50px" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "40px", mb: 2, width: "100%" }}>
                                    <Box style={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "flex-start", borderRadius: "10px", backgroundColor: "#57534E", width: "50%" }}>
                                        <Typography style={{ fontSize: "12px" }} variant="body1">Total</Typography>
                                        <Typography style={{ fontSize: "12px" }} variant="body1">${this.calculateTotal()}</Typography>
                                    </Box>
                                    <Button
                                        onClick={this.handlePay}
                                        variant="contained"
                                        data-test-id="pay-button"
                                        sx={{
                                            borderRadius: "10px", '&:hover': {
                                                backgroundColor: '#a59a00',
                                            }, backgroundColor: "#C2B30A", color: "white", width: "50%", textTransform: "none"
                                        }}>Pay
                                    </Button>
                                </Box>
                            </div>
                        </div>
                        <Box>
                            <Typography variant="h6">Tip</Typography>
                            <RadioGroup
                                data-test-id="tipchange"
                                row
                                value={this.state.tip}
                                onChange={this.handleTipChange}
                                sx={{ mt: 1 }}
                            >
                                {[
                                    {
                                        value: 0.1, label:
                                            <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                                <div>
                                                    10%
                                                </div>
                                                <div>
                                                    $1.80
                                                </div>
                                            </div>
                                    },
                                    {
                                        value: 0.15, label:
                                            <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                                <div>
                                                    15%
                                                </div>
                                                <div>
                                                    $1.80
                                                </div>
                                            </div>
                                    },
                                    {
                                        value: 0.2, label:
                                            <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                                <div>
                                                    20%
                                                </div>
                                                <div>
                                                    $1.80
                                                </div>
                                            </div>
                                    },
                                    {
                                        value: 0, label:
                                            <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                                <div>
                                                    Other
                                                </div>

                                            </div>
                                    },
                                ].map((option) => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio sx={{ display: 'none' }} />}
                                        label={option.label}
                                        sx={{
                                            border: this.state.tip === option.value ? '2px solid #C2B30A' : 'none',
                                            borderRadius: '4px',
                                            padding: '4px 8px',
                                            marginRight: '8px',
                                            cursor: 'pointer',
                                            textAlign: "center",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            width: "100px"
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                        </Box>
                    </div>
                }

                <Dialog
                    open={this.state.open}
                    maxWidth="sm"
                    PaperProps={{
                        style: { background: "transparent", boxShadow: "none" },
                    }}
                >
                    <DialogBackground>
                        <DialogContainer>
                            <SemiTransparentContainer>
                                <Title>
                                    <RedText>Wow:</RedText> Something <br />went wrong!
                                </Title>
                                <Description>
                                    Please verify your payment method and try again
                                </Description>
                            </SemiTransparentContainer>
                            <Button
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.props.history.push("/menu/UserPayments")
                                }}
                                sx={{
                                    height: '55px',
                                    width: '343px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    padding: '16px 24px',
                                    backgroundColor: '#C2B30A',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#a59a00',
                                    },
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    marginTop: "15px"
                                }}
                                data-test-id="tryAgain"
                            >Go back and Try Again</Button>
                            <Button
                                sx={{
                                    height: '55px',
                                    width: '343px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    padding: '16px 24px',
                                    backgroundColor: '#171717',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#333333',
                                    },
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    border: "1px solid white",
                                    marginTop: "10px"
                                }}
                                data-test-id="goToPayBtn"
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.props.history.push("/menu/UserPayments")
                                }}
                            >Go to Payments</Button>
                        </DialogContainer>
                    </DialogBackground>
                </Dialog>
                <Dialog
                    open={this.state.openMenu}
                    maxWidth={false}
                    PaperProps={{
                        style: { background: "black", padding: "10px", boxShadow: "none", color: "white", width: "80%", maxWidth: '850px' },
                    }}
                >
                    <div style={{ padding: "20px 0", display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                            aria-label="close"
                            data-test-id="closeIcon"
                            onClick={() => this.setState({ openMenu: false })}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'white'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <DialogTitle style={{ fontWeight: 700, fontSize: "24px" }}>Don't Miss These Dishes</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} data-test-id="dishesContainer">
                            {this.state.dishes.map((dish, index) => (
                                <Grid
                                    item xs={12} sm={6} key={dish.id} data-test-id="dishes"
                                    md={this.state.dishes.length <= 4 ? 12 / this.state.dishes.length : 3}
                                >
                                    <Card
                                        onClick={() => this.handleSelectDish(dish.id)}
                                        data-test-id="itemCard"
                                        style={{
                                            backgroundColor: "transparent", color: "white", boxShadow: "none", cursor: "pointer", transition: "border 0.3s ease-in-out", borderRadius: "10px",
                                            border: this.state.selectedDish === dish.id ? "2px solid #C2B30A" : "2px solid transparent",
                                        }}
                                    >
                                        <CardMedia
                                            component="img" height="140" image={dish.dishImg?.[0]} alt={dish.title} style={{ borderRadius: "10px 10px 0 0" }}
                                        />
                                        <CardContent style={{ backgroundColor: "#292524", borderRadius: "0 0 10px 10px" }}>
                                            <Typography variant="subtitle1" style={{ fontWeight: "700", fontSize: "14px" }}>
                                                {dish.title}
                                            </Typography>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Typography variant="body2">
                                                    <span style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                        Baker &nbsp;
                                                        <span style={{ color: "rgba(231, 229, 228, 1)" }}>
                                                            {dish.bakerFirstName} &nbsp; {dish.bakerLastName}
                                                        </span>
                                                    </span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    <span style={{ color: "rgba(245, 158, 11, 1)" }}>{dish.rating}</span>
                                                    <img style={{ width: "12px", marginLeft: "5px" }} src={star} alt="star" />
                                                </Typography>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Typography
                                                    variant="body2" fontWeight="bold" style={{ fontWeight: 700, fontSize: "14px" }}
                                                >
                                                    {dish.price} usd
                                                </Typography>
                                            </div>
                                            <Box
                                                sx={{
                                                    gap: 1, fontSize: "10px", display: "flex", alignItems: "center", color: "rgba(168, 162, 158, 1)"
                                                }}
                                            >
                                                <img src={time} alt="time" />
                                                <Typography variant="body2" style={{ fontSize: "10px" }}>
                                                    {dish.time} min
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>


                    <DialogActions
                        sx={{
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: 'center',
                            gap: '16px',
                            padding: "20px 24px"
                        }}
                    >
                        <Button
                            onClick={this.handleNotforNow}
                            sx={{
                                height: '55px',
                                width: '50%',
                                borderRadius: '8px',
                                textTransform: 'none',
                                padding: '16px 24px',
                                backgroundColor: 'black',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                                fontWeight: 700,
                                fontSize: '18px',
                                border: "1px solid white"
                            }}
                            data-test-id='cancelBtn'
                        >Not for Now</Button>

                        <Button
                            onClick={this.addToCart}
                            sx={{
                                height: '55px',
                                width: '50%',
                                borderRadius: '8px',
                                textTransform: 'none',
                                padding: '16px 24px',
                                backgroundColor: '#C2B30A',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#a59a00',
                                },
                                fontWeight: 700,
                                fontSize: '18px',
                            }}
                            data-test-id="addCart"
                        >Add to Cart</Button>




                    </DialogActions>


                </Dialog>
                <Dialog
                    open={this.state.openSuccess}
                    maxWidth="sm"
                    PaperProps={{
                        style: { background: "transparent", boxShadow: "none" },
                    }}
                >
                    <DialogBackground>

                        <DialogContainer>
                            <SemiTransparentYellowContainer>
                                <Title>
                                    <YellowText>Success:</YellowText> Your <br />Order has been placed!
                                </Title>
                                <Description>
                                    Congratulations! Your order has been successfully<br /> created Bon appétit!                                </Description>
                            </SemiTransparentYellowContainer>
                            <Button
                                sx={{
                                    height: '55px',
                                    width: '343px',
                                    borderRadius: '8px',
                                    textTransform: 'none',
                                    padding: '16px 24px',
                                    backgroundColor: '#C2B30A',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#a59a00',
                                    },
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    marginTop: "15px"
                                }}
                                data-test-id="okBtn"
                                onClick={() => {
                                    this.setState({ openSuccess: false })
                                    this.props.history.push("/menu/UserPayments")
                                }}
                            >Ok</Button>
                        </DialogContainer>
                    </DialogBackground>
                </Dialog>

            </div>

        );
    }
}

// Customizable Area Start
const SemiTransparentContainer = styled(Box)({
    width: "400px",
    height: "228px",
    padding: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    textAlign: "center",
    marginLeft: '-30px',
    borderTop: "1px solid #DC2626",
    borderBottom: "1px solid #DC2626",
    marginTop: '10px',
    alignItems: "center"
});

const SemiTransparentYellowContainer = styled(Box)({
    width: "400px",
    height: "228px",
    padding: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    textAlign: "center",
    marginLeft: '-30px',
    borderTop: "1px solid #C2B30A",
    borderBottom: "1px solid #C2B30A",
    marginTop: '10px',
    alignItems: "center",
    paddingBottom: "50px"
});

const DialogBackground = styled(Box)({
    width: "520px",
    height: "460px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",

    backgroundColor: "#171717",

    "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${stripeBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        zIndex: 1,
        opacity: 1,
    },

    "& > *": {
        position: "relative",
        zIndex: 2,
    },
});


const DialogContainer = styled(Box)({
    borderRadius: 12,
    padding: "24px",
    width: "360px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    color: "#fff",
});

const Title = styled(Typography)({
    fontSize: "36px",
    fontWeight: "bold",
    color: "#ffffff",
    marginBottom: "8px",
    marginTop: "30px"
});

const RedText = styled("span")({
    color: "red",
});

const YellowText = styled("span")({
    color: "#C2B30A",
});

const Description = styled(Typography)({
    fontSize: "14px",
    marginBottom: "16px",
});

const styles: { [key: string]: React.CSSProperties } = {
    cartContainer: {
        backgroundColor: "black",
        color: "white",
        padding: "20px",

        height: "100vh",
        display: "flex",
        flexDirection: "column",

        fontFamily: "Roboto",
    },
    cartHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    highlight: {
        color: "yellow",
    },
    cartItems: {
        marginTop: "20px",
        flex: 0.7,
        overflowY: "auto",
        maxHeight: "100%"
    },
    cartItem: {
        display: "flex",
        marginBottom: "20px",
        borderBottom: "1px solid #555",
        paddingBottom: "20px",
    },
    itemImage: {
        width: "80px",
        height: "80px",
        borderRadius: "8px",
        marginRight: "10px",
    },
    itemDetails: {
        flex: 1,
    },
    itemPrice: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
    },
    discountedPrice: {
        fontSize: "18px",
        color: "white",
    },
    main: {
        height: "100%",
        width: "100%"
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
        textTransform: "capitalize"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        marginBottom: "20px",
        borderRadius: "10px",
        marginTop: "30px"
    },
};
// Customizable Area End
