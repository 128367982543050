import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

export const webConfigJSON = require("./config.js");
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export interface Props {
  open: boolean,
  handleClose: any,
  handleOpen: any,
  closeAcceptOrDeliverModal: any;
  orders: {
    to_accept: any[];
    preparing: any[];
    delivered: any[];
  };
  orderId: any;
}

interface S {
  reason: string
}
interface SS { }

export default class DeclineOrderModalController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  editOrderApiCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      reason: ""
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

  }
  async receive(from: string, message: Message) {
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.editOrderApiCallId) {
      if (responseJson && responseJson.message === "Order declined successfully") {
        this.props.handleClose();
        this.props.closeAcceptOrDeliverModal()
      }
    }
  }

  handleDecline = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "token": token
    };
    const formData = new FormData();
    formData.append("order[status]", "declined");
    formData.append("order[decline_reason]", this.state.reason);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editOrderApiCallId = requestMessage.messageId; 
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editordersUrl}/${this.props.orderId}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editdashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage) 

    return true;
  }

  handleReasonChange = (event: any) => {
    this.setState({ reason: event.target.value });
  };
}