import React from "react";
import { Box, Button, Typography, Radio, ListItemText } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import AcceptOrDeliverModalController, { Props } from "./AcceptOrDeliverModalController.web";
import moment from "moment"

export default class AcceptOrDeliverModal extends AcceptOrDeliverModalController {
    constructor(props: Props) {
        super(props);
    }

    renderStatusOptions = () => {
        return this.statusOptions.map(option => (
            <ListItem
                sx={{
                    mb: '8px',
                    background: this.state.selectedStatus === option.value ? '#44403C' : '#171717'
                }}
                disablePadding
                onClick={this.handleChangeStatus.bind(null, option.value)}
            >
                <Radio
                    checked={this.state.selectedStatus === option.value}
                    value={option.value}
                    size="small"
                    color="default"
                    style={{
                        marginRight: 8,
                        color: this.state.selectedStatus === option.value ? '#ffeb3b' : 'white'
                    }}
                />
                <ListItemText primary={option.label} sx={{ color: 'white' }} />
            </ListItem>
        ));
    };

    renderStatusChangeSection = () => {
        return (
            <Grid item xs={12}>
                <Typography style={{ color: "white" }}>Do you want to change the Status of the order?</Typography>
                <List>
                    {this.renderStatusOptions()}
                </List>
            </Grid>
        );
    };

    handleCustomButton = (onClick: any, children: any, styleOverride: any, testId: any) => (
        <Button
            onClick={onClick}
            fullWidth
            sx={{
                width: '343px',
                height: '55px',
                padding: '16px 24px',
                borderRadius: '8px',
                border: "1px solid #ffffff",
                color: '#ffffff',
                backgroundColor: styleOverride?.backgroundColor || 'transparent',
                '&:hover': styleOverride?.hoverColor ? { backgroundColor: styleOverride.hoverColor } : undefined,
                fontSize: '16px',
                fontWeight: 700,
                textTransform: 'none',
                ...styleOverride
            }}
            data-test-id={testId}
        >
            {children}
        </Button>
    );

    renderDeliveredSection = () => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {this.handleCustomButton(
                this.handleAccept,
                "Save Changes",
                { backgroundColor: '#C2B30A', hoverColor: '#a59a00', width: "100%", border: "none", marginBottom: "10px" },
                "handleAccept"
            )}
            {this.handleCustomButton(
                this.props.handleClose,
                "Cancel",
                { border: "1px solid #ffffff", backgroundColor: 'transparent', width: "100%" },
                "cancelBtn"
            )}
        </div>
    );

    enumcard: any = {
        'deliver': "Ready to deliver",
        'preparing ': 'Ready to deliver',
        'accept': "Accept Order",
        'accepted': "Delivered",
        'waiting_for_client': 'Delivered',
        'delivered': "Delivered",
        'payment_completed': 'Accept Order'
      }

    renderOtherTypeSection = () => (
        <>
            {this.handleCustomButton(
                this.props.type === 'accept' ? this.props.openDeclineModal : this.props.handleClose,
                this.props.type === 'accept' ? "Decline order" : "Cancel Order",
                { border: "1px solid #ffffff", backgroundColor: 'transparent' },
                "opn-store3"
            )}
            {this.handleCustomButton(
                this.props.type === "accept" ? this.handleAccept : this.handleWaitingClient,
                this.enumcard[this.props.type],
                { backgroundColor: '#C2B30A', hoverColor: '#a59a00', border: "none" },
                "handleAccept"
            )}
        </>
    );

    render() {
        const getOrderAttributes = () => {
            return this.props.orderData.length > 0 ? this.props.orderData[0].attributes : null;
        };

        const calculateItemTotal = (order: any) => {
            return order.attributes.quantity * Number(order.attributes?.catalogue?.data?.attributes.price);
        };

        const calculateExtrasTotal = (order: any) => {
            return Array.isArray(order.attributes.extras)
                ? order.attributes.extras.reduce((sum: any, extra: any) => sum + Number(extra.price || 0), 0)
                : 0;
        };

        const calculateTotalPrice = () => {
            return this.props.orderData.reduce((total, order) => {
                const itemTotal = calculateItemTotal(order);
                const extrasTotal = calculateExtrasTotal(order);
                return total + itemTotal + extrasTotal;
            }, 0);
        };
        const orderData = getOrderAttributes();
        const totalPrice = calculateTotalPrice();

        return (
            <Dialog
                sx={{
                    padding: '40px 16px',
                    borderRadius: '8px',
                    '& .MuiDialog-paper': {
                        backgroundColor: '#000000',
                    },
                }}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container>
                        <Grid item xs={12}  >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                <Typography variant="h2" sx={{
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    textAlign: "center"
                                }}>
                                    <span style={{ color: "#C2B30A" }}>
                                        Order&nbsp;
                                    </span>
                                    <span style={{ color: "#ffffff" }}>
                                        #{this.props.orderId}
                                    </span>
                                </Typography>
                                <img onClick={this.props.handleClose} src={require("../assets/close.svg")} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "16px 8px 5px 0px" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "12px", color: "#D6D3D1", fontWeight: 400 }} variant="subtitle1" color="text.secondary" component="div">
                                        Client: &nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                        {orderData ? (
                                            <>
                                                {orderData?.customer.data.attributes.first_name}
                                                {orderData?.customer.data.attributes.last_name}
                                            </>
                                        ) : (
                                            <>
                                                {this.state.customer?.first_name}{this.state.customer?.last_name}
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                                <Typography sx={{ fontSize: "12px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                    {orderData?.order_created_at && moment(orderData.order_created_at).format('hh:mm:ss')}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "5px 8px 5px 0px" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "12px", color: "#D6D3D1", fontWeight: 400 }} variant="subtitle1" color="text.secondary" component="div">
                                        Time to pick up: &nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                        {this.state.pickup}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderColor: "#57534E" }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container>
                            {this.props.orderData.length > 0 && (
                                <>
                                    {this.props.orderData.map(order => (
                                        <React.Fragment key={order.id}>
                                            <Grid item xs={12}>
                                                <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                                    {order.attributes.quantity}x
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                                    {order.attributes?.catalogue?.data?.attributes?.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                                    ${order.attributes.catalogue.data.attributes.price.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <List sx={{ listStyleType: 'disc', paddingLeft: '25px', paddingTop: "0px" }}>
                                                    {order.attributes.extras.map(extra => (
                                                        <ListItem key={extra.id} sx={{ display: 'list-item', color: '#ffffff', padding: '3px 2px', fontSize: "14px", fontWeight: "400" }}>
                                                            <Box justifyContent='space-between' display='flex' >
                                                                {extra.name}
                                                                <Typography color={'#ffffff'} sx={{ fontWeight: "700", fontSize: "16px", paddingRight: '41px' }} gutterBottom variant="subtitle1" component="div">
                                                                    ${extra.price}
                                                                </Typography>
                                                            </Box>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                                <Divider sx={{ marginBottom: "15px", borderColor: "#57534E", }} />
                                            </Grid>
                                            <Grid item xs={9}></Grid>
                                        </React.Fragment>
                                    ))}
                                    <Box
                                        sx={{ width: '100%', display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                    >
                                        <Typography sx={{ color: "#A8A29E", fontSize: "16px", fontWeight: 700 }} >
                                            Total: &nbsp;
                                        </Typography>
                                        <Typography sx={{ color: "#ffffff", fontSize: "18px", fontWeight: 700 }} >
                                            ${totalPrice.toFixed(2)}
                                        </Typography>

                                    </Box>
                                </>
                            )}
                            {this.state.data.length != 0 &&
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            {this.state.pickupTime.orderQuantity}x
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            {this.state.pickupTime.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            ${this.state.pickupTime.item_price}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List sx={{ listStyleType: 'disc', paddingLeft: '25px', paddingTop: "0px" }}>
                                            {this.state.pickupTime.extras?.map((extra: any, index: any) => (
                                                <ListItem key={extra.name || index} sx={{ display: 'list-item', color: '#ffffff', padding: '3px 2px', fontSize: "14px", fontWeight: "400" }}>
                                                    <Box display='flex' justifyContent='space-between'>
                                                        {extra.name}
                                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700", paddingRight: '41px' }} gutterBottom variant="subtitle1" component="div">
                                                            ${extra.price}
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            ))}

                                        </List>
                                        <Divider sx={{ borderColor: "#57534E", marginBottom: "15px" }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="flex-end"
                                            sx={{ width: '100%' }}
                                        >
                                            <Typography sx={{ fontSize: "16px", color: "#A8A29E", fontWeight: 700 }} variant="subtitle1" color="text.secondary" component="div">
                                                Total: &nbsp;
                                            </Typography>
                                            <Typography color={'#ffffff'} sx={{ fontSize: "18px", fontWeight: 700, margin: '0' }} gutterBottom variant="subtitle1" component="div">

                                                ${this.state.price}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                            {this.props.type === 'deliveredd' && this.renderStatusChangeSection()}

                        </Grid>
                    </DialogContentText>
                </DialogContent>
                {
                    this.props.type !== 'declined' && (
                        <DialogActions
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '16px',
                                gap: "10px"
                            }}
                        >
                            {this.props.type === 'deliveredd' ? this.renderDeliveredSection() : this.renderOtherTypeSection()}
                        </DialogActions>
                    )
                }
                <div style={{ display: "flex", justifyContent: "center", padding: '16px', }}>
                    {this.props.type == 'declined' && (
                        <Button
                            data-test-id="handleAccept"
                            sx={{
                                width: '343px',
                                padding: '16px 24px',
                                borderRadius: '8px',
                                color: '#ffffff',
                                backgroundColor: 'rgba(199, 22, 29, 1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(199, 22, 29, 1)',
                                },
                                fontSize: '16px',
                                fontWeight: 700,
                                textTransform: 'none'
                            }}
                        >
                            Declined
                        </Button>
                    )}
                </div>
            </Dialog>
        );
    }
}