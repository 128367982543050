import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

export const webConfigJSON = require("./config.js");
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { OrderItem } from "./DashboardController.web";
interface Extra {
  name: string;
  price: number;
}

interface PickupTime {
  customer: {
    data: {
      attributes: {
        first_name: string;
        last_name: string;
      };
    };
  };
  attributes: {
    quantity: number;
    catalogue: {
      data: {
        attributes: {
          name: string;
        };
      };
    };
  };
  time_to_pick_up: string;
  price: number;
  name: string;
  item_price: number;
  orderQuantity: number;
  extras: Extra[];
}

interface Customer {
  first_name: string;
  last_name: string;
}
export interface Props {
  open: boolean,
  handleClose: any,
  handleOpen: any,
  type: string,
  status: string
  openDeclineModal: any,
  orders: {
    to_accept: any[];
    preparing: any[];
    delivered: any[];
  };
  orderId: any;
  getOrdersList: any;
  orderData: OrderItem[]
}
interface S {
  pickupTime: PickupTime,
  customer: Customer,
  pickup: string,
  price: string,
  data: string,
  selectedStatus: string;
}
interface SS { }

export default class AcceptOrDeliverModalController extends BlockComponent<Props, S, SS> {
  apiCallId: string = "";
  editOrderApiCallId: any = "";
  waitingOrderApiCallId: any = "";
  statusOptions = [
    {
      label: "Delivered",
      value: "delivered"
    },
    {
      label: "Preparing",
      value: "accepted"
    }
  ];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      pickupTime: {
        customer: {
          data: {
            attributes: {
              first_name: "",
              last_name: "",
            }
          }
        },
        attributes: {
          quantity: 0,
          catalogue: {
            data: {
              attributes: {
                name: "",
              }
            }
          }
        },
        time_to_pick_up: "",
        price: 0,
        name: "",
        item_price: 0,
        orderQuantity: 0,
        extras: [],
      },
      customer: { first_name: "", last_name: "" },
      pickup: "",
      price: "",
      data: "",
      selectedStatus: 'delivered'
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    super.componentDidMount();
    const orderData = this.props.orderData?.[0];
    if (orderData) {
      this.setState({
        customer: {
          first_name: orderData.attributes.customer.data.attributes.first_name,
          last_name: orderData.attributes.customer.data.attributes.last_name,
        },
      });
    }

    const data = JSON.parse(localStorage.getItem("data") || "[]");
    this.setState({ data })
    let pickupTime: PickupTime = {
      customer: {
        data: {
          attributes: {
            first_name: '',
            last_name: '',
          },
        },
      },
      attributes: {
        quantity: 0,
        catalogue: {
          data: {
            attributes: {
              name: '',
            },
          },
        },
      },
      time_to_pick_up: '',
      price: 0,
      name: '',
      item_price: 0,
      orderQuantity: 0,
      extras: [],
    };

    if (data.length > 0) {
      const customerName = data[0].notifiable.customerName;
      const nameParts = customerName.split(" ");
      const time = data[0].pickupTime;
      pickupTime = {
        customer: {
          data: {
            attributes: {
              first_name: nameParts[0],
              last_name: nameParts[1],
            },
          },
        },
        attributes: {
          quantity: data[0].quantity,
          catalogue: {
            data: {
              attributes: {
                name: data[0].name,
              },
            },
          },
        },
        time_to_pick_up: time,
        price: data[0].price,
        name: data[0].name,
        item_price: data[0].item_price,
        orderQuantity: data[0].order_items_count,
        extras: data[0].extras.map((extra: any) => ({
          name: extra.name,
          price: extra.price,
        })),
      };

      const customer = pickupTime.customer.data.attributes;
      const pickup = pickupTime.time_to_pick_up;
      const price = pickupTime.price.toString();
      this.setState({ pickupTime, customer, pickup, price }, () => {
        this.calculateTotal()
      }
      )
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    const data = localStorage.getItem("data");
    if ((!data || data === "[]") && this.state.data.length !== 0) {
      localStorage.setItem("data", JSON.stringify([]));
      this.setState({ data: "" });
    }
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.editOrderApiCallId) {
      if (responseJson && responseJson.message === "Order accepted successfully") {
        this.props.handleClose();
        this.props.getOrdersList();
      }else if(responseJson.message === "Order marked as payment completed"){
        this.props.handleClose();
        this.props.getOrdersList();
        this.setState({selectedStatus: ""})
      }
    } else if (apiRequestCallId === this.waitingOrderApiCallId) {
      if (responseJson && (responseJson.message === "Order marked as waiting for client" || responseJson.message === "Order marked as delivered")) {
        this.props.handleClose();
        this.props.getOrdersList();
      }
    }
  }
  handleAccept = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "token": token
    };
    const formData = new FormData();
    formData.append("order[status]", this.state.selectedStatus || "accepted");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editordersUrl}/${this.props.orderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editdashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true;
  }

  handleWaitingClient = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "token": token
    };
    const order = this.props.orders.preparing.find((order) => order.id === this.props.orderId);
    const order1 = this.props.orders.delivered.find((order) => order.id === this.props.orderId);

    const formData = new FormData();
    if (order1 && order1.status === "waiting_for_client") {
      formData.append("order[status]", "delivered");
    } else if (order && order.status === "accepted") {
      formData.append("order[status]", "waiting_for_client");
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.waitingOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editordersUrl}/${this.props.orderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editdashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true;
  }

  calculateTotal = () => {
    const { pickupTime } = this.state;
    if (!pickupTime) return;
    const itemPrice = pickupTime.item_price;
    const quantity = Number(pickupTime.orderQuantity) || 1;
    const extrasTotal = pickupTime.extras?.reduce((sum, extra) => sum + (Number(extra.price) || 0), 0) || 0;
    const totalPrice = (itemPrice + extrasTotal) * quantity;
    this.setState({ price: totalPrice.toFixed(0) });
  };

  handleChangeStatus = (selectedStatus: string) => {
    this.setState({ selectedStatus });
  }

}