import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface DishDetails {
  name: string;
  id: string | number;
  price: number;
  description: string;
  variants?: { id: string | number; catalogueId: string | number; name: string }[];
  estimated_time: string;
  ingredients?: { id: string | number; catalogueId: string | number; name: string }[];
  images?: string[];
  extras?: { id: string | number; catalogueId: string | number; name: string; price: number }[];
}
import { AlertColor } from "@mui/material";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: { push: any };
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  selectedValues: string,
  buttonText: string
  activeTab: number,
  selectedValuebakers: string,
  openbakers: boolean,
  categories: any[],
  itemsdishes: any[],
  mostPopularItems: any[],
  items: any[],
  popularItems: any[],
  searchQuery: string,
  openPopup: boolean,
  link: string,
  selectedVariant: string,
  selectedExtras: any[],
  customizedIngredients: any[],
  quantity: number,
  basePrice: number,
  extrasPrice: number,
  showExtras: boolean,
  showIngredients: boolean,
  showVariants: boolean,
  dishId: number,
  mostPopularDishDetails: DishDetails,
  showToast: boolean,
  toastMessage: string,
  toastSeverity: AlertColor;
  totalAmount: number
  ingredientIds: any[]
  variantIds: any[]
  extrasIds: any[]
  activeIndex: number
  initialTime: string;
  availableTimes: string[];
  selectedTime: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DishesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  id = "";
  apiCategoryItemCallId: any = "";
  apiCategoryItemCallIdtwo: any = "";
  apiCategoryItemCallIdthree: any = "";
  apiMostPopularDishesCallId: any = "";
  apiPopularSpecialitiesCallId: any = "";
  apiMostPopularDishDetails: any = "";
  addToCartApi: any = "";
  private mounted: boolean = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    const encodedData = new URLSearchParams(window.location.search).get('data');
    const decodedData = decodeURIComponent(encodedData || '');
    let dishDetails;
    let initialPickupTime;
    let nextOption1;
    let nextOption2;
    try {
      dishDetails = JSON.parse(decodedData);
      const estimated_time = dishDetails?.estimated_time || '';
      const estimatedTime = estimated_time ? parseInt(estimated_time, 10) || 1 : 1;
      const currentTime = new Date();
      currentTime.setMinutes(0, 0, 0);
      initialPickupTime = new Date(currentTime);
      initialPickupTime.setMinutes(currentTime.getMinutes() + estimatedTime);
      nextOption1 = new Date(initialPickupTime);
      nextOption1.setHours(initialPickupTime.getHours() + 1);

      nextOption2 = new Date(nextOption1);
      nextOption2.setHours(nextOption1.getHours() + 1);
    } catch (e) {
      console.error("Invalid JSON format:", e);
    }

    this.state = {
      selectedValues: 'ccreationDateNewest',
      buttonText: "Inprogress",
      selectedValuebakers: "createbakers",
      openbakers: false,
      categories: [
        { id: 1, name: "Cakes", image: "https://via.placeholder.com/60" },
      ],
      items: [],
      mostPopularItems: [],
      popularItems: [],
      searchQuery: "",
      itemsdishes: [],
      openPopup: false,
      link: "",
      selectedVariant: "",
      selectedExtras: [],
      customizedIngredients: [],
      quantity: 1,
      basePrice: 18.0,
      extrasPrice: 0,
      showExtras: true,
      showIngredients: true,
      showVariants: true,
      dishId: 0,
      activeTab: 0,
      mostPopularDishDetails: {
        id: '',
        name: '',
        description: '',
        price: 0,
        estimated_time: '',
        variants: [],
        extras: [],
        ingredients: [],
        images: [],
      },
      showToast: false,
      toastMessage: '',
      toastSeverity: 'success' as AlertColor,
      totalAmount: 0,
      ingredientIds: [],
      variantIds: [],
      extrasIds: [],
      activeIndex: 0,
      initialTime: this.formatTime(initialPickupTime),
      availableTimes: [this.formatTime1(nextOption1), this.formatTime1(nextOption2)],
      selectedTime: this.formatTime1(nextOption1),
    };
    this.mounted = false;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.mounted = true;
    super.componentDidMount();
    this.getCategories();
    this.getCategoriestwo();
    this.getMostPopularDishes();
    this.getPopularSpecialities();
    const storedTab = localStorage.getItem("activeTab");
    if (this.mounted) {
      this.setState({
        activeTab: storedTab !== null ? parseInt(storedTab, 10) : 0,
      });
    }
    const data = localStorage.getItem("dishDetails");
    if (data) {
      const parsedData = JSON.parse(data);
      this.setState({ mostPopularDishDetails: parsedData });
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const encodedData = urlParams.get("data");
      if (encodedData) {
        try {
          const decodedData = JSON.parse(decodeURIComponent(encodedData));
          this.setState({ mostPopularDishDetails: decodedData });
        } catch (error) {
          console.error("Failed to decode and parse data from URL", error);
        }
      }
    }

    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  async componentDidUpdate() {
    const storedTab = localStorage.getItem("activeTab");
    if (storedTab !== null) {
      const parsedTab = parseInt(storedTab, 10);
      if (!isNaN(parsedTab) && this.state.activeTab !== parsedTab) {
        this.setState({
          activeTab: parsedTab,
        });
      }
    }
  }


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const handlers: Record<string, () => void> = {
        [this.apiCategoryItemCallId]: () => {
          const categories = responseJson.data.map((item: any) => ({
            id: item.attributes.id,
            name: item.attributes.name,
            image: item.attributes.picture?.url,
          }));
          this.setState({ categories });
        },
        [this.apiCategoryItemCallIdtwo]: () => {
          const items = responseJson.data.map((item: any) => ({
            id: item.data?.id,
            bakerfirstname: item.data.attributes?.first_name,
            profilepic: item.data.attributes?.profile_pic,
          }));
          this.setState({ items });
        },
        [this.apiMostPopularDishesCallId]: () => {
          this.handleMostPopularDishes(responseJson);
        },
        [this.apiPopularSpecialitiesCallId]: () => {
          this.handlePopularSpecialitiesDishes(responseJson);
        },
        [this.apiMostPopularDishDetails]: () => {
          if (responseJson.error) {
            this.handleShowToast(responseJson.error, "error");
          } else {
            this.handleMostPopularDishDetails(responseJson);
          }
        },
        [this.addToCartApi]: () => {
          if (responseJson.errors) {
            this.handleShowToast(responseJson.errors, "error");
          } else {
            localStorage.setItem("order_id", responseJson.data.id);
            this.handleShowToast("Added to Cart !!", "success");
          }
        },
      };

      if (handlers[apiRequestCallId]) {
        handlers[apiRequestCallId]();
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start

  addToCart = (dishId: any) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const attrs: { [key: string]: any } = {
      catalogue_id: dishId,
      quantity: this.state.quantity,
      price: this.calculateTotal(),
      other_charges: 1.23,
      taxable: true,
      taxable_value: 2.22,
      time_to_pick_up: "22:00",
      ingredient_ids: this.state.ingredientIds,
      extra_ids: this.state.extrasIds,
      variant_ids: this.state.variantIds,
    };

    const httpBody = {
      order_items: {
        ...attrs,
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addToCartApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addToCart
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  formatTime = (date: any) => {
    if (!date || !(date instanceof Date)) {
      console.error("Invalid date provided:", date);
      return "Invalid Date";
    }

    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    let ampm: string = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    let minutesStr: string = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${hours}:${minutesStr} ${ampm}`;
  };

  formatTime1 = (date: any) => {
    if (!date || !(date instanceof Date)) {
      console.error("Invalid date provided:", date);
      return "Invalid Date";
    }

    let hours: number = date.getHours();
    let ampm: string = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    return `${hours} ${ampm}`;
  };



  handleTimeChange = (event: any) => {
    this.setState({ selectedTime: event.target.value });
  };

  getPopularSpecialities = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPopularSpecialitiesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.popularSpecialities}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getMostPopularDishes = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMostPopularDishesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mostPopularDishesEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getCategories = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoryItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoriesUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getCategoriestwo = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoryItemCallIdtwo = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoriesUrltwo}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  handleTabChange = (event: any, newValue: any) => {
    localStorage.setItem("activeTab", newValue);
    if (newValue === 1) {
      this.props.history.push("/menu/bakers")
    }
    this.setState({ activeTab: newValue });
  }
  handleChangebakerss = (event: any) => {
    this.setState({ selectedValuebakers: event.target?.value });
  };
  toggleMenubakers = () => {
    this.setState((prevState) => ({ openbakers: !prevState.openbakers }));
  };

  goTospecialities = (id: any) => {
    localStorage.setItem("baker_id", id);
    setTimeout(() => {
      this.props.history.push("/menu/bakersinfo");
    }, 2000)
  };

  handleShare = (dishId: any) => {
    this.setState({ openPopup: true })
    localStorage.setItem("dishId", dishId);
    localStorage.setItem("baker_id", dishId);
    if (window.location.pathname === "/menu/bakers") {
      this.setState({ link: `${configJSON.url}/menu/Sharebakersinfo/${dishId}` });
    } else if (window.location.pathname === "/menu/dish-Details") {
      const encodedData = localStorage.getItem("encodedData")
      this.setState({ link: `${configJSON.url}/menu/ShareSpeciality?data=${encodedData}` });
    }
  }

  handleClose = () => {
    this.setState({ openPopup: false })
  }

  handleVariantChange = (event: any, id: any) => {
    this.setState({
      selectedVariant: event.target.value,
      variantIds: [id],
    });
  };


  handleExtrasChange = (event: any, id: any) => {
    const { selectedExtras, extrasIds } = this.state;
    const { value, checked } = event.target;
    const price = parseFloat(event.target.dataset.price);

    if (checked) {
      this.setState({
        selectedExtras: [...selectedExtras, value],
        extrasPrice: this.state.extrasPrice + price,
        extrasIds: [...extrasIds, id],
      });
    } else {
      this.setState({
        selectedExtras: selectedExtras.filter((extra) => extra !== value),
        extrasPrice: this.state.extrasPrice - price,
        extrasIds: extrasIds.filter((extrasId) => extrasId !== id),
      });
    }
  };

  handleCustomizeChange = (event: any, id: any) => {
    const { customizedIngredients, ingredientIds } = this.state;
    const { value, checked } = event.target;

    if (checked) {
      if (!ingredientIds.includes(id)) {
        this.setState({
          customizedIngredients: [...customizedIngredients, value],
          ingredientIds: [...ingredientIds, id],
        });
      }
    } else {
      this.setState({
        customizedIngredients: customizedIngredients.filter(
          (ingredient) => ingredient !== value
        ),
        ingredientIds: ingredientIds.filter((ingredientId) => ingredientId !== id),
      });
    }
  };


  handleQuantityChange = (type: any) => {
    this.setState((prevState) => {
      const newQuantity =
        type === "increment" ? prevState.quantity + 1 : prevState.quantity - 1;
      return {
        quantity: newQuantity > 0 ? newQuantity : 1,
      };
    });
  };

  isAnyOptionSelected = () => {
    const { selectedVariant } = this.state;
    return !!selectedVariant;
  };



  calculateTotal = () => {
    const { quantity } = this.state;
    const { extras, price: basePrice } = this.state.mostPopularDishDetails
    const numericBasePrice = Number(basePrice);
    const extrasTotal = extras?.reduce((total, extra) => {
      if (this.state.selectedExtras.includes(extra.name)) {
        const extraPrice = extra.price;
        return total + extraPrice;
      }
      return total;
    }, 0) || 0;
    const numericExtrasTotal = Number(extrasTotal);
    const totalAmount = (numericBasePrice + numericExtrasTotal) * quantity;
    return totalAmount;
  };


  toggleExtrasVisibility = () => {
    this.setState((prevState) => ({
      showExtras: !prevState.showExtras,
    }));
  };

  toggleIngredientsVisibility = () => {
    this.setState((prevState) => ({
      showIngredients: !prevState.showIngredients,
    }));
  };

  toggleVariantsVisibility = () => {
    this.setState((prevState) => ({
      showVariants: !prevState.showVariants,
    }));
  };

  handleMostPopularDishes = (responseJson: any) => {
    const dishes = responseJson.data.map((item: any) => ({
      id: item.id,
      title: item.attributes.name,
      price: item.attributes.price,
      time: item.attributes.estimated_time,
      bakerFirstName: item.attributes.accounts.attributes.first_name,
      bakerLastName: item.attributes.accounts.attributes.last_name,
      rating: item.attributes.average_rating,
      dishImg: item.attributes.images?.map((img: any) => img.url),
    }));
    this.setState({ mostPopularItems: dishes })
  }

  handlePopularSpecialitiesDishes = (responseJson: any) => {
    const dishes = responseJson.data.map((item: any) => ({
      title: item.attributes.name,
      id: item.id,
      time: item.attributes.estimated_time,
      price: item.attributes.price,
      bakerLastName: item.attributes.accounts.attributes.last_name,
      bakerFirstName: item.attributes.accounts.attributes.first_name,
      dishImg: item.attributes.images?.map((img: any) => img.url),
      rating: item.attributes.average_rating,
    }));
    this.setState({ popularItems: dishes })
  }

  handleMostPopularDishDetails = (responseJson: any) => {
    const dishDetails: DishDetails = {
      id: responseJson.data.id,
      name: responseJson.data.attributes.name,
      description: responseJson.data.attributes.description,
      price: responseJson.data.attributes.price,
      estimated_time: responseJson.data.attributes.estimated_time,
      variants: responseJson.data.attributes.variants?.map((variant: any) => ({
        id: variant.id,
        catalogueId: variant.catalogue_id,
        name: variant.name,
      })),
      extras: responseJson.data.attributes.extras?.map((extra: any) => ({
        id: extra.id,
        catalogue_id: extra.catalogue_id,
        name: extra.name,
        price: extra.price,
      })),
      ingredients: responseJson.data.attributes.ingredients?.map((ingredient: any) => ({
        id: ingredient.id,
        catalogue_id: ingredient.catalogue_id,
        name: ingredient.name,
      })),
      images: responseJson.data.attributes.images?.map((image: any) => image.url),
    };

    this.setState(
      { mostPopularDishDetails: dishDetails },
      () => {
        const encodedData = encodeURIComponent(JSON.stringify(dishDetails));
        const shareableUrl = `/menu/dish-Details?data=${encodedData}`;
        localStorage.setItem("encodedData", encodedData);
        localStorage.setItem("dishDetails", JSON.stringify(dishDetails));
        this.props.history.push(shareableUrl);
      }
    );
  };


  getMostPopularDishDetails = (id: any) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMostPopularDishDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mostPopularDisheDetailsEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleShowToast = (message: string, severity: AlertColor) => {
    this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
  }

  handleCloseToast = () => {
    this.setState({ showToast: false });
  }

  handleSlideChange = (index: any) => {
    this.setState({ activeIndex: index });
  };
  // Customizable Area End
}