import React from "react";

// Customizable Area Start
import {
  Typography,
  Button,
  Box,
  Grid,
  CardContent,
  Card,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";






// Customizable Area End

import OrderInprogressController, {
  Props,
} from "./OrderInprogressController.web";

export default class OrderInprogress extends OrderInprogressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start 
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <Box sx={{
          color: "white",
          height: "500px",
          overflowY: "auto",
          width: "100%"

        }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", color: "#C2B30A" }}>
            Your <span style={{ color: "white" }}>Order</span>
          </Typography>
          {this.state.orders.map((order: any) => (
            <Card key={order.id} sx={{ bgcolor: "#292524", color: "white", mt: 3 }}>
              <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                <CardMedia
                  component="img"
                  sx={{ width: "56px", height: "56px", objectFit: "cover", marginTop: "1rem" }}
                  image={order.items[0]?.images[0]}
                  alt="Product Image"
                />
                <CardContent style={{ marginTop: 0, width: "100%" }}>
                  <div>
                    <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1, fontSize: "16px" }}>
                      {order.items[0]?.product.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body1" sx={{ fontSize: "12px", color: "white" }}>
                      {order.items[0]?.product.description}
                    </Typography>
                  </div>
                  <Typography variant="body2" sx={{ fontWeight: "bold", mt: 1 }}>
                    <span style={{ color: "#A8A29E", fontSize: "18px" }}>
                      ${order.items[0]?.product.price * order.items[0]?.product.quantity}
                    </span>
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#57534E",
                        color: "#fff",
                        width: "128px",
                        borderRadius: "8px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#6C6962",
                        },
                      }}
                    >
                      {order.items[0]?.product.quantity}
                    </Button>
                  </div>
                </CardContent>
              </Box>
            </Card>
          ))}

          <Box
            sx={{
              color: 'white',
              borderRadius: '8px',
              width: '100%',
            }}
          >
            <Grid container spacing={2} sx={{ marginTop: "2rem" }} >
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
                  Cost Summary
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <Typography>Products</Typography>
                  <Typography>$18.00</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <Typography>Tips</Typography>
                  <Typography>$1.80</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <Typography>Fee</Typography>
                  <Typography>$2.00</Typography>
                </Box>
              </Grid>

              <Grid item xs={7} sx={{ textAlign: 'right' }}>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "6rem", marginTop: "4rem", paddingRight: "10px" }}>
                  <div>
                    <Typography variant="body2" sx={{ marginBottom: '8px' }}>
                      Time to cancel:
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
                      {this.state.cancellationTime}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: '#DC2626',
                        textTransform: "none",
                        color: '#DC2626',
                        '&:hover': {
                          backgroundColor: '#DC2626',
                          color: 'white',
                          borderColor: '#DC2626',
                        },
                      }}
                      onClick={this.handleOpen}
                    >
                      Cancel Order
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>

        </Box>

        <div>
          <Dialog open={this.state.open} onClose={this.handleClose}
            PaperProps={{
              sx: {
                maxWidth: '480px',
                width: '100%',
                padding: '16px',
                borderRadius: "8px",
                backgroundColor: "black"
              },
            }}
          >
            <DialogTitle>
              <Typography variant="h5" align="center" sx={{ fontSize: "32px", color: "white", fontWeight: 700 }}>
                Are you sure to <br />
                <strong style={{ color: "#C2B30A" }}>delete your order?</strong>
              </Typography>
            </DialogTitle>

            <DialogContent
              sx={{
                flex: 'initial',

              }}

            >
              {this.state.cancellationTime === "00:00:00" && (
                <div style={{ marginTop: "2rem" }}>
                  <Typography align="center" color="textSecondary" sx={{ fontSize: "14px", color: "white" }}>
                    If you cancel the order, you will be charged with the services fee because you are out the allowed cancellation period.
                  </Typography>
                  <Typography align="center" color="textSecondary" sx={{ fontSize: "16px", color: "white", marginTop: "2rem" }}>
                    Fee <strong style={{ marginLeft: "20px" }}>$2,00</strong>
                  </Typography>
                </div>
              )}
              {this.state.cancellationTime !== "00:00:00" && (
                <Typography align="center" color="textSecondary" sx={{ fontSize: "14px", color: "white" }}>
                  You won’t be charged because you are within the allowed cancellation period.
                </Typography>
              )}
            </DialogContent>

            <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
              <Button
                onClick={this.handleClose}
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(194, 179, 10, 1)',
                  color: 'white',
                  fontWeight: 'bold',
                  width: "343px",
                  padding: "0.7rem",
                  border: "none",
                  '&:hover': { backgroundColor: '#C2B30A' },

                }}
              >
                <span style={{ fontSize: "18px", textTransform: "none" }}> No, Go Back</span>

              </Button>
              <Button
                onClick={this.handleClose}
                variant="outlined"
                sx={{
                  backgroundColor: '#171717',
                  color: '#fff',
                  fontWeight: 'bold',
                  width: "343px",
                  padding: "0.7rem",
                  '&:hover': { backgroundColor: '#171717' },
                  marginTop: "10px",
                  textTransform: 'none',
                  border: "1px solid white"
                }}
              ><span style={{ fontSize: "18px" }}> Yes, Delete Order</span>

              </Button>
            </div>
          </Dialog>
        </div>

     
           </>
              
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {

};
// Customizable Area End