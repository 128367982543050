import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import { FormikTouched } from "formik";

export interface Props {


}
type dayKeys = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
type dayValues = {
    label: string;
    val: dayKeys;
    isChecked: boolean,
    open?: string;
    close?: string;
}
interface S {
    profile_pic: any,
    user: any,
    open: boolean,
    daysOfWeek: dayValues[],
    error: string | null
}

interface SS {
    id: any;
}

export default class UserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    putUserProfilePicRequestId: string = "";
    putUserProfileRequestId: string = "";

    getUserProfileRequestId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            profile_pic: "",
            open: false,
            user: {},
            error: '',
            daysOfWeek: [
                { label: 'Monday', val: 'Mon', isChecked: false, open: '08', close: '20' },
                { label: 'Tuesday', val: 'Tue', isChecked: false, open: '08', close: '20' },
                { label: 'Wednesday', val: 'Wed', isChecked: false, open: '08', close: '20' },
                { label: "Thursday", val: 'Thu', isChecked: false, open: '08', close: '20' },
                { label: 'Friday', val: 'Fri', isChecked: false, open: '08', close: '20' },
                { label: 'Saturday', val: 'Sat', isChecked: false, open: '08', close: '20' },
                { label: 'Sunday', val: 'Sun', isChecked: false, open: '08', close: '20' }
            ],
        };


        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
            this.setState({ error: "" });

            if (apiRequestCallId == this.putUserProfilePicRequestId) {
                if (responseJson.data) {
                    this.setState({
                        profile_pic: responseJson?.data?.attributes?.profile_pic
                    }, () => {
                        const user = JSON.parse(localStorage.getItem('user') || '{}');
                        user.data.attributes.profile_pic = responseJson?.data?.attributes?.profile_pic;
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('profile_pic', this.state.profile_pic);
                    })
                    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                }
            } else if (apiRequestCallId == this.putUserProfileRequestId) {
                if (responseJson?.errors) {
                    this.setState({ error: 'This phone number is already registered to another user' });
                } else {
                    this.getProfileDetails();
                }

            } else if (apiRequestCallId == this.getUserProfileRequestId) {
                const working_days = responseJson.data.attributes.working_days.split('-');
                const openingHours = responseJson.data.attributes.opening_hours;
                this.setState(prev => ({
                    user: this.transformData({
                        ...responseJson.data.attributes,
                    }),
                    profile_pic: responseJson.data.attributes.profile_pic,
                    daysOfWeek: prev.daysOfWeek.map(day => {
                        const openTime = openingHours[day.label]?.open;
                        const closeTime = openingHours[day.label]?.close;
                        const formattedOpenTime = openTime?.split(':')[0];
                        const formattedCloseTime = closeTime?.split(':')[0];
                        return {
                            ...day, 
                            isChecked: working_days.includes(day.label),
                            open: formattedOpenTime,
                            close: formattedCloseTime,
                        };
                    })
                }));
            }
        }


    }

    async componentDidMount() {
        super.componentDidMount();
        let pic = localStorage.getItem('profile_pic');
        this.setState({ profile_pic: pic })
        const userString = localStorage.getItem('user');

        if (userString) {
            let user: any = JSON.parse(userString);
            this.getProfileDetails();
            if (user && user.data) {
                this.setState({
                    user: this.transformData(user.data.attributes),
                });
            }
        }
    }


    transformData = (data: any) => {

        const {
            first_name,
            last_name,
            full_phone_number,
            country_code,
            email,
            addresses,
            state,
            zipcode,
            opening_hours
        } = data;
        const addresse = addresses?.map((item: any) => item.address);
        const address = addresse.join(", ");
        const phone = full_phone_number ? full_phone_number.replace(country_code, '') : '';
        return {
            firstName: first_name || '',
            lastName: last_name || '',
            phone: phone || '',
            countryCode: country_code || '',
            email: email || '',
            address: address || '',
            state: state || '',
            zipcode: zipcode || '',
            opening_hours: opening_hours
        };
    };

    saveProfile = (values: any) => {
        const token: any = localStorage.getItem('authToken');
        const currentUrl = window.location.href;
        const endpoint = new URL(currentUrl).pathname;
        const isUserSettings = endpoint === '/menu/userSettings';

        let httpBody = {
            data: {
                attributes: {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    full_phone_number: `${values.countryCode}${values.phone}`,
                    country_code: values.countryCode,
                    email: !isUserSettings ? values.email : undefined,
                    address: {
                        address: values.address,
                    },
                    state: values.state,
                    zipcode: values.zipcode,
                    insta_link: values.instaLink,
                    facebook_link: values.fbLink,
                    working_days: !isUserSettings
                        ? this.state.daysOfWeek.reduce((acum, cur) => ({ ...acum, [cur.label]: cur.isChecked }), {})
                        : undefined,
                    opening_hours: !isUserSettings
                        ? this.state.daysOfWeek.filter(day => day.isChecked)
                            .reduce((acum, cur) => ({
                                ...acum,
                                [cur.label]: {
                                    open: String(cur.open).padStart(2, '0') + ":00",
                                    close: String(cur.close).padStart(2, '0') + ":00"
                                }
                            }), {})
                        : undefined,
                },
            },
        };

        const cleanObject = (obj: any) => {
            Object.keys(obj).forEach(key => {
                if (obj[key] === undefined) {
                    delete obj[key];
                } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    cleanObject(obj[key]);
                }
            });
        };
        cleanObject(httpBody);

        localStorage.setItem('user', JSON.stringify(httpBody));
        const event = new CustomEvent('userProfileUpdated', { detail: httpBody });
        window.dispatchEvent(event);

        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.putUserProfileRequestId = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateProfile
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            })
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypePut
        );

        runEngine.sendMessage(message.id, message);
        this.handleOpen();
    };


    updateProfilePic(files: any) {

        const token: any = localStorage.getItem('authToken');
        let formData = new FormData()
        formData.append("account[profile_pic]", files[0]);
        let httpBody = formData;

        const header = {
            "Accept": "*/*",
            "token": token
        }

        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.putUserProfilePicRequestId = message.messageId;
        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateProfilePic}`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypePut
        );


        runEngine.sendMessage(message.id, message);
    }
    handleOpen = () => {
        this.setState({
            open: true,
        });
    }
    handleClose = () => {
        this.setState({
            open: false,
        })
    }
    handlCheckDay(key: dayKeys) {
        this.setState(prev => ({ daysOfWeek: prev.daysOfWeek.map(dayData => dayData.val === key ? { ...dayData, isChecked: !dayData.isChecked } : dayData) }))
    }
    handleInputChange = (day: string, key: 'open' | 'close', event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/\D/g, '');
        if (inputValue !== '') {
            const numberValue = +inputValue;
            if (numberValue >= 0 && numberValue <= 23) {
                this.setState(prev => ({ daysOfWeek: prev.daysOfWeek.map(dayData => dayData.val === day ? ({ ...dayData, [key]: numberValue < 10 ? `0${numberValue}` : numberValue }) : dayData) }))
            }
        } else {
            this.setState(prev => ({ daysOfWeek: prev.daysOfWeek.map(dayData => dayData.val === day ? ({ ...dayData, [key]: '' }) : dayData) }))

        }
    };
    getProfileDetails() {
        const token: any = localStorage.getItem('authToken');

        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserProfileRequestId = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProfile}`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.validationApiContentType,
                token: token,
            })
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeGet
        );

        runEngine.sendMessage(message.id, message);
    }

    getErrorValue = (field: string, errors: any) => errors[field] && typeof errors[field] === 'string' ? errors[field] : undefined;
    getTouchedValue = (field: string, touched: FormikTouched<any> | undefined): boolean | undefined => {
        if (typeof touched === 'object' && touched !== null && field in touched) {
            return touched[field] as boolean;
        }
        return undefined;
    };
    getLabel = (field: string): string => {
        return field === 'firstName' ? 'First Name' : 'Last Name';
    };
    getLabel1 = (field: string): string => {
        if (field === 'instaLink') {
            return 'Instagram Link (optional)';
        }
        if (field === 'fbLink') {
            return 'Facebook Link (optional)';
        }
        return field.charAt(0).toUpperCase() + field.slice(1);
    };

    handleCountryCodeChange = (event: any) => {
        const newCountryCode = event.target.value;
        this.setState((prevState) => ({
          ...prevState,
          countryCode: newCountryCode,
        }));
      };
}
