import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { imgBell } from "./assets";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController.web";

// Customizable Area End

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotification = (notification: any) => {
    const statusColors = {
      accepted: "#009168",
      "waiting to pickup": "#2563EB",
      preparing: "#01BAEC",
      delivered: "#2563EB",
      default: "#C2B30A",
    } as const;

    const getStatusColor = (status: string) =>
      statusColors[status as keyof typeof statusColors] || statusColors.default;

    return (
      <Box key={notification.id}>
        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", gap: 3 }}>
          <img style={{ width: 48, height: 48, borderRadius: '4px' }} src={require("../assets/dish.png")} alt="Notification" />
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '12px' }}>
                Order #{notification.id}
              </Typography>
            </Box>
            <List sx={{ listStyleType: 'disc', color: "#A8A29E", fontSize: '10px', width: "35%", fontWeight: 400, display: "flex", padding: 0 }}>
              <ListItem sx={{ padding: 0 }}>
                <Typography variant="h6" sx={{ fontSize: '10px', fontWeight: 400 }}>
                  {this.calculateRelativeTime(notification.created_at)}
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: 0 }}>
                <Typography variant="h6" sx={{ fontSize: '10px', fontWeight: 400 }}>
                  {notification.first_name} {notification.last_name}
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: 0 }}>
                <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '10px' }}>
                  Pick up: 17:00 pm
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Box>
        <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "4.5rem" }}>
          <Button sx={{ float: "right", padding: "5px 15px", borderRadius: "15px", fontSize: "10px", color: "#ffffff", backgroundColor: getStatusColor(notification.status), '&:hover': { backgroundColor: getStatusColor(notification.status) }, fontWeight: 700, textTransform: "capitalize", height: "30px" }}>
            {notification.status}
          </Button>
          <Box onClick={() => window.location.pathname === "/menu/userNotifications" ? this.props.history.push(`/menu/userHistoryDetails/${notification.id}`) : this.goToOrders(notification.notificationId)} data-test-id="btn">
            <Button sx={{ padding: '8px 16px', borderRadius: '8px', fontSize: '10px', color: '#ffffff', backgroundColor: notification.is_read ? 'transparent' : '#C2B30A', border: notification.is_read ? "1px solid #ffffff" : "none", '&:hover': { backgroundColor: notification.is_read ? 'transparent' : '#a59a00' }, fontWeight: 700, textTransform: 'none', float: "right", marginRight: '15px' }}>
              See order
            </Button>
          </Box>
        </div>
        <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box sx={{
          width: '100%', height: "100%", scrollbarWidth: 'none',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
            <Typography variant="h6" sx={{
              fontSize: '28px',
              fontWeight: 700,
            }}>
              <span style={{ color: "#ffffff", marginLeft: "3px" }}>
                Notifications
              </span>
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
            <Tabs
              value={this.state.tab}
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#C2B30A',
                },
              }}
              onChange={(event, value) => this.handleChange(event, value)}
              aria-label="secondary tabs example"

              data-test-id="tab"
            >
              <Tab value="one"
                sx={{
                  color: '#ffffff',
                  textTransform: 'none',
                  fontWeight: '700',
                  '&.Mui-disabled': {
                    color: '#78716C',
                    opacity: 1,
                    pointerEvents: 'none',
                  },
                  '&.Mui-selected': {
                    color: '#ffffff',
                  },
                }} label="All" />
              <Tab value="two"
                sx={{
                  color: '#ffffff',
                  fontWeight: '700',
                  textTransform: 'none',
                  '&.Mui-disabled': {
                    pointerEvents: 'none',
                    color: '#78716C',
                    opacity: 1,
                  },
                  '&.Mui-selected': {
                    color: '#ffffff',
                    fontWeight: "bold"
                  },
                  width: "10%"
                }} label="Read" />
              <Tab value="three"
                sx={{
                  color: '#ffffff',
                  textTransform: 'none',
                  fontWeight: '700',
                  '&.Mui-disabled': {
                    color: '#78716C',
                    pointerEvents: 'none',
                    opacity: 1,
                  },
                  '&.Mui-selected': {
                    color: '#ffffff',
                  },
                  width: "10%"
                }} label="Unread" />
            </Tabs>

            <Typography
              variant="h6" sx={{
                color: "#A8A29E",
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: "16px",
                textDecoration: "underline",
                textUnderlineOffset: "0.5em",
                cursor: "pointer"
              }}
              onClick={this.markAllRead}
              data-test-id="markAllRead"
            >
              Mark all as read
            </Typography>
          </Box>

          <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />
          <Box sx={{ height: '60%', scrollbarWidth: 'none', overflowY: 'scroll', '&::-webkit-scrollbar': { display: 'none' } }}>
            {this.state.tab === "one" && <Box>{this.state.notifications.map(this.renderNotification)}</Box>}
            {this.state.tab === "two" && <Box>{this.state.notifications.filter(notification => notification.is_read).map(this.renderNotification)}</Box>}
            {this.state.tab === "three" && <Box>{this.state.notifications.filter(notification => !notification.is_read).map(this.renderNotification)}</Box>}
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
