import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const webConfigJSON = require("./config.js");
export const configJSON = require("./config");
import { AlertColor } from "@mui/material";

export interface Props {
  open: boolean,
  handleClose: any,
  handleOpen: any,
  openStore: any,
  close: any,
  handleClose1: any,
  closeStore: any,
}
interface S {
  activeTab: number,
  showToast: boolean,
  toastMessage: string,
  toastSeverity: AlertColor;
  openFAQ: any,
}
interface SS { }

export default class OpenBakeryModalController extends BlockComponent<Props, S, SS> {
  changestoreApiCallId: string = "";
  closeStoreApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      activeTab: 0,
      showToast: false,
      toastMessage: '',
      toastSeverity: 'success' as AlertColor,
      openFAQ: null,
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    super.componentDidMount();
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId == this.changestoreApiCallId) {
        if (responseJson?.data?.attributes) {
          this.props.openStore();
          localStorage.setItem('isStoreOpen', JSON.stringify(true));
        } else {
          this.onSignupFail(responseJson);
        }
      }else if(apiRequestCallId == this.closeStoreApiCallId){
        if (responseJson?.data?.attributes) {
          this.props.closeStore();
          localStorage.removeItem('isStoreOpen');
        } else {
          this.onSignupFail(responseJson);
        }
      }

    }
  }
  onSignupFail(responseJson: any) {
    let errorMessage = "Something went wrong";
    if (responseJson.message) {
      errorMessage = responseJson.message;
    }
    this.handleShowToast(errorMessage, "error");
    this.parseApiErrorResponse(responseJson);
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }


  handleShowToast = (message: string, severity: AlertColor) => {
    this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
  }


  handleCloseToast = () => {
    this.setState({ showToast: false });
  }

  handleTabChange = (event: any, newValue: any) => {
    this.setState({ activeTab: newValue });
  }

  openStore = () => {
    let token = localStorage.getItem("authToken");
    let user = localStorage.getItem("user");
    let id;
    if (user) {
      let parsedUser = JSON.parse(user);
      id = parsedUser.data.attributes.merchant.data.id;
    }

    const header = {
      "token": token
    };
    const formData = new FormData();
    formData.append("merchant[is_open]", "true");

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changestoreApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.changestoreApiEndPoint}/${id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.storeApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  closeStore = () => {
    let token = localStorage.getItem("authToken");
    let user = localStorage.getItem("user");
    let id;
    if (user) {
      let parsedUser = JSON.parse(user);
      id = parsedUser.data.attributes.merchant.data.id;
    }

    const header = {
      "token": token
    };
    const formData = new FormData();
    formData.append("merchant[is_open]", "false");

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.closeStoreApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.changestoreApiEndPoint}/${id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.storeApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  toggleFAQ = (index: any) => {
    this.setState((prevState) => ({
      openFAQ: prevState.openFAQ === index ? null : index,
    }));
  };
}