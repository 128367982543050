Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.getOrderDetails="/bx_block_shopping_cart/order_items/cart_items"
  exports.listOfOrdersMethod = "GET"
  exports.mostPopularDishesEndPoint = "bx_block_catalogue/most_popular_dishes";
  exports.addToCartApi = "/bx_block_shopping_cart/order_items"
  exports.proceedtoPay = "/bx_block_stripe_integration/payments/create_checkout_session"
  // Customizable Area End
  