import React from "react";
import DishesController, {
    Props,
} from "./DishesController.web";
import { StyleSheet } from "react-native";
import {
    Container,
    Typography,
    Box,
    Radio,
    RadioGroup,
    Checkbox,
    FormGroup,
    Button,
    IconButton,
    Card,
    CardContent,
    CardMedia,
    Snackbar, Alert, MenuItem, Select
} from "@mui/material";
import Slider from "react-slick";
import OutlinedInput from '@mui/material/OutlinedInput';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SharePopup from "./SharePopup.web";
import { backgroundImg, upArrow, down, share } from "./assets";
export default class DishDetails extends DishesController {
    constructor(props: Props) {
        super(props);
    }
    settings = {
        slidesToShow: 1,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        speed: 500,
        dots: true,
        arrows: false,
    };
    render() {
        const isButtonActive = this.isAnyOptionSelected();

        return (
            <Container sx={{ marginTop: 4, overflowY: "scroll" }}>
                <Snackbar
                    open={this.state.showToast}

                    autoHideDuration={6000}

                    onClose={this.handleCloseToast}

                    data-test-id="btnClose"
                >

                    <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
                        {this.state.toastMessage}
                    </Alert>

                </Snackbar>
                <Card sx={{ display: "flex", flexDirection: "column", marginBottom: 4, backgroundColor: "transparent" }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        data-testid="images"
                    >
                        <div style={{ width: "40%" }}>
                            <Slider
                                {...this.settings}
                                slidesToShow={1}
                                centerMode
                                infinite
                                data-testid="slider"
                            >
                                {this.state.mostPopularDishDetails?.images?.map((imageData, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '10px',
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "120px",
                                                transition: 'width 0.3s ease',
                                                display: 'block',
                                                margin: '0 auto',
                                            }}
                                            src={imageData}
                                            alt={`Slide ${index}`}
                                        />
                                    </div>
                                ))}
                            </Slider>

                        </div>
                    </Box>
                    <CardContent style={{ color: "white", padding: "50px 0 20px 0" }}>
                        <Typography variant="h5" component="div" gutterBottom>
                            {this.state.mostPopularDishDetails.name}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {this.state.mostPopularDishDetails.description}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 2, }}>
                            <Typography variant="h6" style={{ color: "white" }}>
                                ${this.state.mostPopularDishDetails.price}
                            </Typography>
                            {window.location.pathname !== "/menu/ShareSpeciality" && (
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Typography data-test-id="share2" onClick={this.handleShare} variant="body1" sx={{ fontSize: "12px", color: "#C2B30A", ml: 2 }}>
                                        Share Specialty
                                        <img src={share} alt="share" />
                                    </Typography>
                                </div>
                            )
                            }
                        </Box>
                    </CardContent>
                </Card>

                <Box sx={{ marginBottom: 4, color: "white" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom>
                            Variants
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center", width: "124px" }}>
                            <Button
                                style={{
                                    width: "80px",
                                    height: "25px",
                                    color: "white",
                                    backgroundColor: "rgba(52, 211, 153, 1)",
                                    borderRadius: "20px",
                                    fontSize: "8px",
                                }}
                            >
                                Mandatory
                            </Button>
                            <img
                                style={{ cursor: "pointer", marginLeft: "10px" }}
                                src={this.state.showVariants ? upArrow : down}
                                alt="icon"
                                data-test-id="toggleVariantsVisibility"
                                onClick={this.toggleVariantsVisibility}
                            />
                        </div>
                    </div>
                    {this.state.mostPopularDishDetails.variants && (
                        <RadioGroup
                            name="variants"
                            value={this.state.selectedVariant}
                            data-test-id="variants"
                        >
                            {this.state.mostPopularDishDetails.variants?.map((variant) => (
                                <Box
                                    key={variant.id}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>{variant.name}</Typography>
                                    <Radio
                                        onChange={(event) => { this.handleVariantChange(event, variant.id) }}
                                        value={variant.name}
                                        checked={this.state.selectedVariant === variant.name}
                                        sx={{
                                            color: "rgba(168, 162, 158, 1)",
                                            "&.Mui-checked": {
                                                color: "rgba(194, 179, 10, 1)",
                                            },
                                        }}
                                    />
                                </Box>
                            ))}
                        </RadioGroup>
                    )}

                </Box>

                <Box sx={{ marginBottom: 4, color: "white" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom>
                            Extras
                        </Typography>
                        <img
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            src={this.state.showExtras ? upArrow : down}
                            alt="image"
                            data-test-id="toggleExtrasVisibility"
                            onClick={this.toggleExtrasVisibility}
                        />
                    </div>
                    {this.state.mostPopularDishDetails.extras && (
                        <FormGroup>
                            {this.state.mostPopularDishDetails.extras.map((extra) => (
                                <Box
                                    key={extra.name}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>{extra.name}</Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <Typography sx={{ marginRight: 1, fontSize: "12px" }}>
                                            +${(Number(extra.price) || 0).toFixed(2)} USD
                                        </Typography>
                                        <Checkbox
                                            value={extra.name}
                                            data-price={extra.price}
                                            checked={this.state.selectedExtras?.includes(extra.name)}
                                            onChange={(event) => this.handleExtrasChange(event, extra.id)}
                                            data-test-id="selectedExtras"
                                            sx={{
                                                color: "rgba(168, 162, 158, 1)",
                                                "&.Mui-checked": {
                                                    color: "rgba(194, 179, 10, 1)",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </FormGroup>
                    )}

                </Box>

                <Box sx={{ marginBottom: 4, color: "white" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom>
                            Customize Ingredients
                        </Typography>
                        <img
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            src={this.state.showIngredients ? upArrow : down}
                            alt="image"
                            data-test-id="toggleIngredientsVisibility"
                            onClick={this.toggleIngredientsVisibility}
                        />
                    </div>
                    {this.state.mostPopularDishDetails.ingredients && (
                        <FormGroup>
                            {this.state.mostPopularDishDetails.ingredients.map((ingredient) => (
                                <Box
                                    key={ingredient.id}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>{ingredient.name}</Typography>
                                    <Checkbox
                                        value={ingredient.name}
                                        checked={this.state.customizedIngredients.includes(ingredient.name)}
                                        onChange={(event) => { this.handleCustomizeChange(event, ingredient.id) }}
                                        data-test-id="customizedIngredients"
                                        sx={{
                                            color: "rgba(168, 162, 158, 1)",
                                            "&.Mui-checked": {
                                                color: "rgba(194, 179, 10, 1)",
                                            },
                                        }}
                                    />
                                </Box>
                            ))}
                        </FormGroup>
                    )}

                </Box>

                <Box sx={{ marginBottom: 4, color: "white" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom style={{ width: "100%" }}>
                            Time to Pick up
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <OutlinedInput
                                    fullWidth
                                    style={{
                                        ...styles.inputLabel,
                                        border: "none !important",
                                    }}
                                    id="outlined-adornment-email"
                                    type="text"
                                    value={this.state.initialTime}
                                    data-test-id="emailInput"
                                    inputProps={{
                                        style: {
                                            textAlign: "center",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    readOnly
                                />

                                <span style={{ margin: "0px 15px" }}>to</span>
                                <Select
                                    fullWidth
                                    value={this.state.selectedTime}
                                    onChange={this.handleTimeChange}
                                    style={{ ...styles.inputLabel, textAlign: "center" }}
                                    data-test-id="availableTimes"
                                >
                                    {this.state.availableTimes.map((time) => (
                                        <MenuItem key={time} value={time}>
                                            {time}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </div>
                        </Typography>
                    </div>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginBottom: 4,
                        color: "white"
                    }}
                >
                    <Typography variant="h6">
                        Total: ${this.calculateTotal().toFixed(2)}
                    </Typography>
                    {window.location.pathname !== "/menu/ShareSpeciality" && (
                        <div style={{ display: "flex", width: "40%", gap: "10px", alignItems: "flex-end", }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                backgroundColor: "#57534E",
                                color: "#fff",
                                width: "128px",
                                borderRadius: "8px",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "#6C6962",
                                },
                                height: "56px"
                            }}>
                                <IconButton
                                    style={{ color: "white" }}
                                    data-test-id="decrement"
                                    onClick={() => this.handleQuantityChange("decrement")}
                                >
                                    <RemoveIcon />
                                </IconButton>
                                <Typography>{this.state.quantity}</Typography>
                                <IconButton
                                    onClick={() => this.handleQuantityChange("increment")}
                                    style={{ color: "white" }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Box>
                            <div
                                style={styles.buttonsWrapper}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    data-test-id="btnLogin"
                                    disabled={!isButtonActive}
                                    style={{
                                        color: "white",
                                        backgroundColor: isButtonActive ? "rgba(194, 179, 10, 1)" : "rgba(87, 83, 78, 1)",
                                        padding: "15px",
                                        fontWeight: "700",
                                        textTransform: 'none',
                                        borderRadius: 8
                                    }}
                                    onClick={() => { this.addToCart(this.state.mostPopularDishDetails.id) }}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    )}
                </Box>
                <SharePopup open={this.state.openPopup} handleClose={this.handleClose} link={this.state.link} />
            </Container>
        );
    }
}

const styles = StyleSheet.create({
    buttonsWrapper: {
        flex: 1,
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "50%"
    },
    inputLabel: {
        color: "#FFFFFF",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
})